import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/home/index.vue')
    },
    {
        path: '/lp',
        name: 'Lp',
        component: () => import('../views/lp/index-v2.vue')
    },
    {
        path: '/lpv1',
        name: 'Lpv1',
        component: () => import('../views/lp/index-v1.vue')
    },
    {
        path: '/record',
        name: 'Record',
        component: () => import('../views/lp/record-v2.vue')
    },
    {
        path: '/recordv1',
        name: 'Recordv1',
        component: () => import('../views/lp/record-v1.vue')
    },
    {
        path: '/award',
        name: 'Award',
        component: () => import('../views/award/index.vue')
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/user/index.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior() {
        return {top: 0 }
    },
    routes
})

// 解决跳转到当前路由报错问题
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch((err) => err)
// }


export default router
