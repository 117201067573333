const zh = {
    alert: {
        reLogin: '賬號發生改變，請重新登錄',
        copied: '復製成功',
        connectWallet: '請連接錢包',
        notBound: '未綁定邀請者，請進行綁定',
        inputParent: '請輸入邀請者地址',
        addressError: '請輸入正確的錢包地址',
        parentError: '邀請者地址未驗證，請重新輸入',
        boundSuccess: '賬號綁定成功',
        boundFailed: '賬號綁定失敗，請重試',
        success: '成功',
        failed: '失敗',
        soon: '即將開放...',
        insufficient: '余額不足',
        quotaInsufficient: '額度不足',
        amountError: '金額不正確',
        minAmountError: '最低',
        redeemed: '訂單不存在或已贖回',
        enter: '確定',
        congratulation: '恭喜您合成'
    },
    tabbar: {
        home: 'ID LABS',
        lp: '質押',
        award: '獎勵',
        user: '我的',
        whitepaper: 'Telegram',
        record: '記錄',
        login: '登錄',
        plan: '獎勵計劃',
        currentLp: '當前質押',
        dealRecord: '交易記錄',
        lock: '安全',
        money: '資產',
        quit: '登出',
        invite: '請綁定邀請人地址，開啟ID LABS質押之旅',
        binding: '立即綁定',
        btn: '解鎖錢包'
    },
    award:{
        title: '獎勵',
        desc: '分享ID LABS，助力平臺營銷，將獲得高額獎勵',
        await: '待領取獎勵',
        get: '領取',
        all: '獎勵總計',
        yetGet: '已領取',
        record: '獎勵記錄',
        allAward: '總獎勵',
        type1: '直推獎勵',
        type2: '級別獎勵',
        type3: '分紅獎勵',
        type4: '平級獎勵',
        more: '更多...'
    },
    user: {
        title: '我的個人中心',
        desc: '社區管理，通過您的邀請鏈接邀請更多朋友註冊，組建自己的營銷社區',
        date: '註冊時間',
        type1: '直推成員',
        type2: '團隊成員',
        type3: '個人質押',
        type4: '團隊質押',
        my: '我的邀請鏈接',
        copy: '復製',
        myCommunity: '我的社區',
        cur: '當前',
        look: '查看下級',
        bindAddress: '請綁定邀請人地址，開啟ID LABS質押之旅',
        inputAddress: '請輸入邀請人地址',
        bind: '綁定'
    },
    home:{
        title1: '去中心化，非托管，',
        title2: '資金安全的一站式質押平臺',
        desc: 'ID LABS是一個去中心、非托管和開源的質押平臺，引入了人工智能 (AI)於DeFi質押，其主要好處是圍繞金融交易生成大量公共數據和這一龐大的金融數據集合可用於訓練和改進人工智能 (AI) 模型，例如試圖從預期資產價格波動中獲得最大收益的套利機器人。',
        lp: '質押',
        whitepaper: '白皮書',
        earningTitle: '贏取穩健收益',
        earning: {
            title1: '堅持原則',
            desc1: 'ID LABS的設計基於強大的原則：為長期性提供穩健的交易平臺，社區優先和在各方面的公平處理，並優先考慮穩定的財富創造。',
            title2: '儀式效益',
            desc2: 'ID LABS獎勵與我們一起共同創造平臺的夥伴和該協定目的在於獎勵社區團隊和付出最多貢獻的夥伴。',
            title3: '促進發展',
            desc3: 'ID LABS為平臺用戶提供了一個優良的開發啟動計劃，也提供了一個豐厚的獎勵計劃，幫助用戶建立他們的組織網絡。'
        },
        valueTitle: '我們的核心價值',
        value: {
            title1: '一切掌控',
            desc1: '所有用戶資金都被鎖定在智能合約中，沒有任何第三方可以直接訪問這些資金。ID LABS 是非托管的，從不控製任何資產。 它可以直接從您控製的錢包中使用。',
            title2: '簡單操作',
            desc2: 'ID LABS 的頁面設計簡單以及容易上手，只需輕松點擊幾個按鈕，就能做買賣，讓用戶們更加順暢的使用。',
            title3: '公開處理',
            desc3: 'ID LABS 會通過各種社交平臺，讓會員們了解公司發展的每一層階段並持續性公布最新消息。',
            title4: '社區',
            desc4: 'ID LABS 就像個大家庭，是一家社區所有的公司，因此我們非常鼓勵用戶們在開發，營銷，資訊共享等方面進行合作。',
            title5: '目光長遠',
            desc5: 'ID LABS 希望能建立更多的長期合作夥伴關系。我們相信長久的計劃將帶來更多的投資，不該止步於短暫的趨勢。',
            title6: '信用',
            desc6: '在加密貨幣方面，信任是無可替代的。我們會確保用戶的資金和資料的安全，並保證會持續為大家帶來更多的利潤。',
        },
        friendTitle: '我們的合作夥伴'
    },
    lp: {
        title: '質押池',
        desc: 'AI自動投資模型為您創造價值，只需要質押一些代幣即可賺取，高APR，低風險',
        back: '返回質押',
        popupTitle: '質押USDT',
        record: '質押記錄',
        usdtLp: '質押USDT - 賺取USDT',
        redeem: '贖回',
        money: '金額',
        startDate: '質押時間',
        endDate: '到期時間',
        lp: '質押',
        day: '天',
        lpYet: '已質押',
        earn: '已賺取收益',
        extract: '可提取收益',
        get: '收獲',
        cancel: '取消質押',
        valueTilte: '為什麽選擇我們?',
        type1Title: '全球曝光',
        type1Desc: '觸及全球超過100萬的 ID LABS用戶。',
        type2Title: '流動性',
        type2Desc: 'TVL超過100,000,000 USDT，隨時取消質押或提取收益，實時到賬',
        type3Title: '高APR',
        type3Desc: '成熟的AI模型，迅速發現高利機會，讓平臺資金高速流轉，APR高達98.55%-365.00%',
        type4Title: '營銷助力',
        type4Desc: '分享ID LABS平臺，獲得超高營銷獎勵',
        usable: '可用',
        max: '最大',
        accredit: '授權',
        quotaRemain: '剩余額度'
    }
}

export default zh;
