const zh = {
    alert: {
        reLogin: 'アカウントが変更されました。再度ログインしてください',
        copied: 'コピーに成功しました',
        connectWallet: 'ウォレットを接続してください',
        notBound: 'バインドされていない招待者、バインドしてください',
        inputParent: '招待者のアドレスを入力してください',
        addressError: '正しいウォレットアドレスを入力してください',
        parentError: '招待者のアドレスが確認されていません。再入力してください',
        boundSuccess: 'アカウントは正常にバインドされました',
        boundFailed: 'アカウントのバインドに失敗しました。もう一度お試しください。',
        success: '成功',
        failed: '失敗',
        soon: 'まもなくオープン...',
        insufficient: '残高不足',
        quotaInsufficient: '割り当てが不十分です',
        amountError: '間違った金額',
        minAmountError: '最低',
        redeemed: '注文が存在しないか、すでに引き換えられています',
        enter: 'もちろん',
        congratulation: '合成おめでとうございます'
    },
    tabbar: {
        home: 'IDラボ',
        lp: '誓約',
        award: 'アワード',
        user: '私の',
        whitepaper: 'Telegram',
        record: '記録',
        login: 'ログイン',
        plan: '報酬プログラム',
        currentLp: '現在の誓約',
        dealRecord: '取引記録',
        lock: '安全性',
        money: '資産',
        quit: 'サインアウト',
        invite: 'ID LABS 誓約の旅を開始するには、招待者のアドレスをバインドしてください',
        binding: '今すぐバインドします',
        btn: 'ウォレットのロックを解除する'
    },
    award:{
        title: 'アワード',
        desc: 'ID LABS を共有し、プラットフォームのマーケティングを支援し、高額の報酬を獲得しましょう',
        await: '保留中の報酬',
        get: '受け取る',
        all: '賞金総額',
        yetGet: '受け取った',
        record: '報酬記録',
        allAward: '報酬総額',
        type1: '直接紹介報酬',
        type2: 'レベル報酬',
        type3: '配当金ボーナス',
        type4: 'レベル',
        more: 'もっと...'
    },
    user: {
        title: '私の個人的な中心',
        desc: 'コミュニティ管理、招待リンクを通じてさらに多くの友達を招待して登録し、独自のマーケティング コミュニティを形成します',
        date: '登録時間',
        type1: 'ダイレクトプッシュメンバー',
        type2: 'チームメンバー',
        type3: '個人的な誓約',
        type4: 'チームの誓い',
        my: '私の招待リンク',
        copy: 'コピー',
        myCommunity: '私のコミュニティ',
        cur: '現在',
        look: '部下を表示する',
        bindAddress: 'ID LABS 誓約の旅を開始するには、招待者のアドレスをバインドしてください',
        inputAddress: '招待者のアドレスを入力してください',
        bind: '縛る'
    },
    home:{
        title1: '分散型、非管理型、',
        title2: '資金セキュリティのためのワンストップの誓約プラットフォーム',
        desc: 'ID LABS は、DeFi ステーキングに人工知能 (AI) を導入する分散型、非保管型、オープンソースのステーキング プラットフォームです。その主な利点は、金融取引に関して大量の公開データが生成され、この膨大な金融データのコレクションが可能であることです。予測される資産価格の変動からの利益を最大化しようとする裁定取引ボットなどの人工知能 (AI) モデルのトレーニングと改善に使用されます。',
        lp: '誓約',
        whitepaper: 'Whitepaper',
        earningTitle: '安定した収入を得る',
        earning: {
            title1: '原則に固執する',
            desc1: 'ID LABS の設計は、長期にわたる堅牢な取引プラットフォームを提供し、コミュニティ優先とあらゆる面で公正な扱いを提供し、安定した富の創造を優先するという強力な原則に基づいています。',
            title2: '儀式上の利益',
            desc2: 'ID LABS は、プラットフォームを共同作成したパートナーに報酬を与えます。この契約の目的は、最も貢献したコミュニティ チームとパートナーに報酬を与えることです。',
            title3: '開発を改善する',
            desc3: 'ID LABS は、プラットフォーム ユーザーに優れた開発スタートアップ プランを提供し、ユーザーの組織ネットワークの構築を支援する寛大な報酬プランも提供します。'
        },
        valueTitle: '私たちの核となる価値観',
        value: {
            title1: 'すべてはコントロール下にある',
            desc1: 'すべてのユーザー資金はスマート コントラクトにロックされており、第三者がこれらの資金に直接アクセスすることはできません。 ID LABS は保管をしておらず、いかなる資産も管理しません。あなたが管理するウォレットから直接使用できます。',
            title2: '簡単な操作',
            desc2: 'ID LABSのページデザインはシンプルで使いやすく、ボタンを数回クリックするだけで売買ができるため、よりスムーズにご利用いただけます。',
            title3: '公開処理',
            desc3: 'ID LABSは、メンバーがさまざまなソーシャルプラットフォームを通じて会社の発展の各段階を理解できるようにし、最新ニュースを継続的に発表します。',
            title4: 'コミュニティ',
            desc4: 'ID LABS は大きな家族のようなコミュニティ所有の会社であるため、開発、マーケティング、情報共有などに協力することをユーザーに強くお勧めします。',
            title5: '長期的なビジョン',
            desc5: 'ID LABS は、より長期的なパートナーシップを確立したいと考えています。私たちは、長期的な計画がさらなる投資につながると信じており、短期的な傾向にとどまるべきではありません。',
            title6: 'クレジット',
            desc6: '暗号通貨に関しては信頼に代わるものはありません。私たちはユーザーの資金と情報の安全を確保し、皆様により多くの利益をもたらし続けることを保証します。',
        },
        friendTitle: '私たちのビジネスパートナー'
    },
    lp: {
        title: 'ステーキングプール',
        desc: 'AI 自動投資モデルが価値を生み出します。獲得するにはいくつかのトークンをプレッジするだけで済み、高い APR、低いリスクが得られます。',
        back: '誓約書を返す',
        popupTitle: 'USDTの誓約',
        record: '誓約書',
        usdtLp: 'USDT をステーク - USDT を獲得',
        redeem: '償還',
        money: '総額',
        startDate: '誓約時間',
        endDate: '有効期限',
        lp: '誓約',
        day: '空',
        lpYet: '誓約した',
        earn: '獲得した',
        extract: '引き出し可能な収入',
        get: '褒美',
        cancel: '誓約をキャンセルする',
        valueTilte: 'なぜ私たちを選ぶのですか?',
        type1Title: '世界的な暴露',
        type1Desc: '世界中の 100 万人を超える ID LABS ユーザーにリーチします。',
        type2Title: '流動性',
        type2Desc: 'TVL は 100,000,000 USDT を超えます。いつでも誓約をキャンセルしたり収入を引き出すことができ、アカウントにはリアルタイムで入金されます。',
        type3Title: '高い年利率',
        type3Desc: '成熟した AI モデルは高収益の機会を迅速に発見し、プラットフォームが高速で資金を送金できるようにし、APR は 98.55% ～ 365.00% と高くなります。',
        type4Title: 'マーケティング支援',
        type4Desc: 'ID LABS プラットフォームを共有して、超高額のマーケティング報酬を獲得しましょう',
        usable: '利用可能',
        max: '最大',
        accredit: '認可された',
        quotaRemain: '残量'
    }
}

export default zh;
