const zh = {
    alert: {
        reLogin: 'Tài khoản đã được thay đổi, vui lòng đăng nhập lại',
        copied: 'sao chép thành công',
        connectWallet: 'Vui lòng kết nối ví',
        notBound: 'Người mời không liên kết, vui lòng liên kết',
        inputParent: 'Vui lòng nhập địa chỉ của người mời',
        addressError: 'Vui lòng nhập đúng địa chỉ ví',
        parentError: 'Địa chỉ của người mời chưa được xác minh, vui lòng nhập lại',
        boundSuccess: 'Tài khoản được liên kết thành công',
        boundFailed: 'Liên kết tài khoản không thành công, vui lòng thử lại',
        success: 'thành công',
        failed: 'thất bại',
        soon: 'Sắp mở cửa...',
        insufficient: 'Thiếu cân bằng',
        quotaInsufficient: 'Hạn ngạch không đủ',
        amountError: 'số tiền không chính xác',
        minAmountError: 'thấp nhất',
        redeemed: 'Đơn hàng không tồn tại hoặc đã được đổi',
        enter: 'Chắc chắn',
        congratulation: 'Chúc mừng sự tổng hợp của bạn'
    },
    tabbar: {
        home: 'ID PHÒNG THÍ NGHIỆM',
        lp: 'lời hứa',
        award: 'phần thưởng',
        user: 'của tôi',
        whitepaper: 'Telegram',
        record: 'Ghi',
        login: 'Đăng nhập',
        plan: 'chương trình khen thưởng',
        currentLp: 'cam kết hiện tại',
        dealRecord: 'Ghi lại giao dịch',
        lock: 'Sự an toàn',
        money: 'tài sản',
        quit: 'Đăng xuất',
        invite: 'Vui lòng liên kết địa chỉ của người mời để bắt đầu hành trình cam kết ID LABS',
        binding: 'ràng buộc ngay bây giờ',
        btn: 'mở khóa ví'
    },
    award:{
        title: 'phần thưởng',
        desc: 'Chia sẻ ID LABS, giúp tiếp thị nền tảng và nhận phần thưởng cao',
        await: 'treo giải',
        get: 'nhận được',
        all: 'tổng giải thưởng',
        yetGet: 'đã nhận',
        record: 'hồ sơ khen thưởng',
        allAward: 'tổng phần thưởng',
        type1: 'phần thưởng giới thiệu trực tiếp',
        type2: 'phần thưởng cấp độ',
        type3: 'thưởng cổ tức',
        type4: 'Lớp phẳng',
        more: 'Hơn...'
    },
    user: {
        title: 'trung tâm cá nhân của tôi',
        desc: 'Quản lý cộng đồng, mời thêm bạn bè đăng ký thông qua liên kết lời mời của bạn và hình thành cộng đồng tiếp thị của riêng bạn',
        date: 'Thời gian đăng ký',
        type1: 'thành viên đẩy trực tiếp',
        type2: 'thành viên của đội',
        type3: 'cam kết cá nhân',
        type4: 'đội cam kết',
        my: 'liên kết mời của tôi',
        copy: 'sao chép',
        myCommunity: 'cộng đồng của tôi',
        cur: 'hiện hành',
        look: 'Xem cấp dưới',
        bindAddress: 'Vui lòng liên kết địa chỉ của người mời để bắt đầu hành trình cam kết ID LABS',
        inputAddress: 'Vui lòng nhập địa chỉ của người mời',
        bind: 'để ràng buộc'
    },
    home:{
        title1: 'phi tập trung, không giam giữ,',
        title2: 'Nền tảng cam kết một cửa để bảo đảm quỹ',
        desc: 'ID LABS là một nền tảng đặt cược phi tập trung, không giam giữ và nguồn mở, đưa trí tuệ nhân tạo (AI) vào đặt cược DeFi. Lợi ích chính của nền tảng này là một lượng lớn dữ liệu công khai được tạo ra xung quanh các giao dịch tài chính và bộ sưu tập dữ liệu tài chính khổng lồ này có thể được sử dụng để đào tạo và cải thiện các mô hình Trí tuệ nhân tạo (AI), chẳng hạn như bot chênh lệch giá cố gắng tối đa hóa lợi nhuận từ biến động giá tài sản dự kiến.',
        lp: 'lời hứa',
        whitepaper: 'Whitepaper',
        earningTitle: 'kiếm thu nhập ổn định',
        earning: {
            title1: 'bám sát nguyên tắc',
            desc1: 'Thiết kế của ID LABS dựa trên các nguyên tắc mạnh mẽ: cung cấp một nền tảng giao dịch mạnh mẽ vì lợi ích lâu dài, ưu tiên cộng đồng và đối xử công bằng về mọi mặt, đồng thời ưu tiên tạo ra của cải ổn định.',
            title2: 'lợi ích nghi lễ',
            desc2: 'ID LABS thưởng cho các đối tác đồng sáng tạo nền tảng với chúng tôi và mục đích của thỏa thuận này là thưởng cho các nhóm cộng đồng và đối tác đóng góp nhiều nhất.',
            title3: 'cải thiện sự phát triển',
            desc3: 'ID LABS cung cấp một kế hoạch khởi động phát triển tuyệt vời cho người dùng nền tảng và cũng cung cấp một kế hoạch phần thưởng hào phóng để giúp người dùng xây dựng mạng lưới tổ chức của họ.'
        },
        valueTitle: 'giá trị cốt lõi của chúng tôi',
        value: {
            title1: 'tất cả mọi thứ trong tầm kiểm soát',
            desc1: 'Tất cả tiền của người dùng được khóa trong hợp đồng thông minh và không bên thứ ba nào có quyền truy cập trực tiếp vào các khoản tiền này. ID LABS không giam giữ và không bao giờ kiểm soát bất kỳ tài sản nào. Nó có thể được sử dụng trực tiếp từ ví bạn kiểm soát.',
            title2: 'Hoạt động đơn giản',
            desc2: 'Thiết kế trang của ID LABS đơn giản, dễ sử dụng, chỉ cần click vài nút là có thể mua bán, giúp người dùng sử dụng thuận lợi hơn.',
            title3: 'xử lý công khai',
            desc3: 'ID LABS sẽ cho các thành viên hiểu từng giai đoạn phát triển của công ty thông qua các nền tảng xã hội khác nhau và liên tục thông báo những tin tức mới nhất.',
            title4: 'Cộng đồng',
            desc4: 'ID LABS giống như một đại gia đình, một công ty thuộc sở hữu của cộng đồng nên chúng tôi rất khuyến khích người dùng hợp tác phát triển, marketing, chia sẻ thông tin, v.v.',
            title5: 'tầm nhìn dài hạn',
            desc5: 'ID LABS hy vọng sẽ thiết lập được nhiều mối quan hệ đối tác lâu dài hơn. Chúng tôi tin rằng việc lập kế hoạch dài hạn sẽ dẫn đến đầu tư nhiều hơn và không nên dừng lại ở các xu hướng ngắn hạn.',
            title6: 'Tín dụng',
            desc6: 'Không có sự thay thế nào cho niềm tin khi nói đến tiền điện tử. Chúng tôi sẽ đảm bảo an toàn cho tiền và thông tin của người dùng, đồng thời đảm bảo rằng chúng tôi sẽ tiếp tục mang lại nhiều lợi nhuận hơn cho mọi người.',
        },
        friendTitle: 'đối tác kinh doanh của chúng tôi'
    },
    lp: {
        title: 'nhóm đặt cược',
        desc: 'Mô hình đầu tư tự động AI tạo ra giá trị cho bạn, bạn chỉ cần cầm cố một số token để kiếm tiền, APR cao, rủi ro thấp',
        back: 'hoàn trả cam kết',
        popupTitle: 'Cầm cố USDT',
        record: 'biên bản cam kết',
        usdtLp: 'Stake USDT - Kiếm USDT',
        redeem: 'chuộc lỗi',
        money: 'số tiền',
        startDate: 'thời gian cam kết',
        endDate: 'Hạn sử dụng',
        lp: 'lời hứa',
        day: 'bầu trời',
        lpYet: 'cam kết',
        earn: 'kiếm được',
        extract: 'Thu nhập có thể rút',
        get: 'phần thưởng',
        cancel: 'hủy cam kết',
        valueTilte: 'Tại sao chọn chúng tôi?',
        type1Title: 'tiếp xúc toàn cầu',
        type1Desc: 'Tiếp cận hơn 1 triệu người dùng ID LABS trên toàn thế giới.',
        type2Title: 'tính trôi chảy',
        type2Desc: 'TVL vượt quá 100.000.000 USDT, bạn có thể hủy cam kết hoặc rút thu nhập bất kỳ lúc nào và tài khoản sẽ được ghi có theo thời gian thực',
        type3Title: 'APR cao',
        type3Desc: 'Mô hình AI trưởng thành nhanh chóng phát hiện ra các cơ hội sinh lời cao, cho phép nền tảng chuyển tiền với tốc độ cao và APR cao tới 98,55% -365,00%',
        type4Title: 'Hỗ trợ tiếp thị',
        type4Desc: 'Chia sẻ nền tảng ID LABS và nhận phần thưởng tiếp thị siêu cao',
        usable: 'có sẵn',
        max: 'tối đa',
        accredit: 'ủy quyền',
        quotaRemain: 'số tiền còn lại'
    }
}

export default zh;
