const zh = {
    alert: {
        reLogin: 'Akun telah diubah, silakan masuk lagi',
        copied: 'berhasil menyalin',
        connectWallet: 'Silakan hubungkan dompet',
        notBound: 'Pengundang yang tidak terikat, harap ikat',
        inputParent: 'Masukkan alamat pengundang',
        addressError: 'Masukkan alamat dompet yang benar',
        parentError: 'Alamat pengundang tidak diverifikasi, harap masukkan kembali',
        boundSuccess: 'Akun berhasil diikat',
        boundFailed: 'Pengikatan akun gagal, harap coba lagi',
        success: 'kesuksesan',
        failed: 'gagal',
        soon: 'Segera dibuka...',
        insufficient: 'Saldo tidak mencukupi',
        quotaInsufficient: 'Kuota tidak mencukupi',
        amountError: 'jumlah yang salah',
        minAmountError: 'terendah',
        redeemed: 'Pesanan tidak ada atau telah ditukarkan',
        enter: 'Tentu',
        congratulation: 'Selamat atas sintesis Anda'
    },
    tabbar: {
        home: 'ID LABS',
        lp: 'sumpah',
        award: 'menghadiahkan',
        user: 'milikku',
        whitepaper: 'Telegram',
        record: 'Catatan',
        login: 'Gabung',
        plan: 'program hadiah',
        currentLp: 'janji saat ini',
        dealRecord: 'Catatan transaksi',
        lock: 'Keamanan',
        money: 'aktiva',
        quit: 'Keluar',
        invite: 'Harap ikat alamat pengundang untuk memulai perjalanan janji ID LABS',
        binding: 'mengikat sekarang',
        btn: 'buka kunci dompet'
    },
    award:{
        title: 'menghadiahkan',
        desc: 'Bagikan ID LABS, bantu pemasaran platform, dan dapatkan imbalan tinggi',
        await: 'hadiah yang tertunda',
        get: 'menerima',
        all: 'penghargaan total',
        yetGet: 'diterima',
        record: 'catatan hadiah',
        allAward: 'imbalan total',
        type1: 'hadiah rujukan langsung',
        type2: 'imbalan tingkat',
        type3: 'Bonus dividen',
        type4: 'Tingkat',
        more: 'Lagi...'
    },
    user: {
        title: 'pusat pribadi saya',
        desc: 'Manajemen komunitas, undang lebih banyak teman untuk mendaftar melalui tautan undangan Anda, dan bentuk komunitas pemasaran Anda sendiri',
        date: 'Waktu pendaftaran',
        type1: 'anggota dorong langsung',
        type2: 'anggota tim',
        type3: 'janji pribadi',
        type4: 'janji tim',
        my: 'tautan undangan saya',
        copy: 'menyalin',
        myCommunity: 'Komunitas saya',
        cur: 'saat ini',
        look: 'Lihat bawahan',
        bindAddress: 'Harap ikat alamat pengundang untuk memulai perjalanan janji ID LABS',
        inputAddress: 'Masukkan alamat pengundang',
        bind: 'untuk mengikat'
    },
    home:{
        title1: 'terdesentralisasi, non-penahanan,',
        title2: 'Platform janji satu atap untuk keamanan dana',
        desc: 'ID LABS adalah platform staking sumber terbuka, non-penahanan, dan terdesentralisasi yang memperkenalkan kecerdasan buatan (AI) ke staking DeFi. Manfaat utamanya adalah sejumlah besar data publik dihasilkan seputar transaksi keuangan dan kumpulan data keuangan yang sangat besar ini dapat digunakan untuk pelatihan dan peningkatan model Artificial Intelligence (AI), seperti bot arbitrase yang berupaya memaksimalkan keuntungan dari fluktuasi harga aset yang diharapkan.',
        lp: 'sumpah',
        whitepaper: 'Whitepaper',
        earningTitle: 'memperoleh penghasilan tetap',
        earning: {
            title1: 'berpegang pada prinsip',
            desc1: 'Rancangan ID LABS didasarkan pada prinsip-prinsip yang kuat: menyediakan platform perdagangan yang kuat untuk jangka panjang, prioritas komunitas dan perlakuan yang adil dalam semua aspek, dan memprioritaskan penciptaan kekayaan yang stabil.',
            title2: 'Manfaat ritual',
            desc2: 'ID LABS memberi penghargaan kepada mitra yang membuat platform bersama kami dan tujuan dari perjanjian ini adalah untuk memberi penghargaan kepada tim komunitas dan mitra yang berkontribusi paling banyak.',
            title3: 'meningkatkan pembangunan',
            desc3: 'ID LABS memberikan rencana awal pengembangan yang sangat baik untuk pengguna platform, dan juga memberikan rencana hadiah yang besar untuk membantu pengguna membangun jaringan organisasi mereka.'
        },
        valueTitle: 'nilai inti kami',
        value: {
            title1: 'semuanya terkendali',
            desc1: 'Semua dana pengguna dikunci dalam kontrak pintar dan tidak ada pihak ketiga yang memiliki akses langsung ke dana ini. ID LABS bersifat non-penahanan dan tidak pernah mengontrol aset apa pun. Ini dapat digunakan langsung dari dompet yang Anda kendalikan.',
            title2: 'operasi sederhana',
            desc2: 'Desain halaman ID LABS sederhana dan mudah digunakan, Anda hanya perlu mengklik beberapa tombol untuk membeli dan menjual, memungkinkan pengguna untuk menggunakannya dengan lebih lancar.',
            title3: 'Pemrosesan publik',
            desc3: 'ID LABS akan membuat anggota memahami setiap tahap perkembangan perusahaan melalui berbagai platform sosial dan terus mengumumkan berita terbaru.',
            title4: 'Masyarakat',
            desc4: 'ID LABS seperti keluarga besar, perusahaan milik komunitas, jadi kami sangat mendorong pengguna untuk bekerja sama dalam pengembangan, pemasaran, berbagi informasi, dll.',
            title5: 'visi jangka panjang',
            desc5: 'ID LABS berharap dapat membangun lebih banyak kemitraan jangka panjang. Kami percaya bahwa perencanaan jangka panjang akan menghasilkan lebih banyak investasi, dan tidak boleh berhenti pada tren jangka pendek.',
            title6: 'Kredit',
            desc6: 'Tidak ada pengganti untuk kepercayaan dalam hal cryptocurrency. Kami akan memastikan keamanan dana dan informasi pengguna, dan memastikan bahwa kami akan terus memberikan lebih banyak keuntungan bagi semua orang.',
        },
        friendTitle: 'mitra bisnis kami'
    },
    lp: {
        title: 'Mengintai kolam',
        desc: 'Model investasi otomatis AI menciptakan nilai bagi Anda, Anda hanya perlu menjaminkan beberapa token untuk menghasilkan, APR tinggi, risiko rendah',
        back: 'janji kembali',
        popupTitle: 'Ikrar USDT',
        record: 'catatan janji',
        usdtLp: 'Pertaruhkan USDT - Dapatkan USDT',
        redeem: 'penebusan',
        money: 'jumlah',
        startDate: 'Waktu sumpah',
        endDate: 'Tanggal kadaluarsa',
        lp: 'sumpah',
        day: 'langit',
        lpYet: 'berjanji',
        earn: 'Diperoleh',
        extract: 'Pendapatan yang dapat ditarik',
        get: 'hadiah',
        cancel: 'membatalkan janji',
        valueTilte: 'Mengapa memilih kami?',
        type1Title: 'paparan global',
        type1Desc: 'Jangkau lebih dari 1 juta pengguna ID LABS di seluruh dunia.',
        type2Title: 'ketidakstabilan',
        type2Desc: 'TVL melebihi 100.000.000 USDT, Anda dapat membatalkan kontribusi atau menarik pendapatan kapan saja, dan akun akan dikreditkan secara real time',
        type3Title: 'April tinggi',
        type3Desc: 'Model AI yang matang dengan cepat menemukan peluang keuntungan tinggi, memungkinkan platform untuk mentransfer dana dengan kecepatan tinggi, dan APR setinggi 98,55%-365,00%',
        type4Title: 'Dukungan pemasaran',
        type4Desc: 'Bagikan platform ID LABS dan dapatkan imbalan pemasaran super tinggi',
        usable: 'tersedia',
        max: 'maksimum',
        accredit: 'resmi',
        quotaRemain: 'jumlah tersisa'
    }
}

export default zh;
