const zh = {
    alert: {
        reLogin: '계정이 변경되었습니다. 다시 로그인하십시오.',
        copied: '성공적으로 복사',
        connectWallet: '지갑을 연결해주세요',
        notBound: '구속 해제된 초대자, 구속하십시오',
        inputParent: '초대하는 사람의 주소를 입력하세요.',
        addressError: '정확한 지갑주소를 입력해주세요',
        parentError: '초대한 사람의 주소가 확인되지 않았습니다. 다시 입력하십시오.',
        boundSuccess: '계정이 성공적으로 결합되었습니다.',
        boundFailed: '계정 결합에 실패했습니다. 다시 시도해 주세요.',
        success: '성공',
        failed: '실패하다',
        soon: '곧 개장...',
        insufficient: '잔액 불충분',
        quotaInsufficient: '할당량 부족',
        amountError: '잘못된 금액',
        minAmountError: '최저',
        redeemed: '주문이 존재하지 않거나 사용되었습니다.',
        enter: '확신하는',
        congratulation: '합성 축하드립니다'
    },
    tabbar: {
        home: '아이디랩스',
        lp: '약속',
        award: '상',
        user: '내 거',
        whitepaper: 'Telegram',
        record: '기록',
        login: '로그인',
        plan: '보상 프로그램',
        currentLp: '현재 서약',
        dealRecord: '거래기록',
        lock: '안전',
        money: '자산',
        quit: '로그아웃',
        invite: 'ID LABS 서약 여정을 시작하려면 초대한 사람의 주소를 바인딩하세요.',
        binding: '지금 바인딩',
        btn: '지갑 잠금 해제'
    },
    award:{
        title: '상',
        desc: 'ID LABS를 공유하고, 플랫폼 마케팅을 돕고, 높은 리워드를 받으세요',
        await: '보류 중인 보상',
        get: '받다',
        all: '총상',
        yetGet: '받았다',
        record: '보상 기록',
        allAward: '총 보상',
        type1: '직접 추천 보상',
        type2: '레벨 보상',
        type3: '배당금 보너스',
        type4: '동급',
        more: '더...'
    },
    user: {
        title: '내 개인 센터',
        desc: '커뮤니티 관리, 초대 링크를 통해 더 많은 친구를 초대하여 등록하고 나만의 마케팅 커뮤니티 형성',
        date: '등록 시간',
        type1: '직접 푸시 회원',
        type2: '팀 구성원',
        type3: '개인 서약',
        type4: '팀 서약',
        my: '내 초대 링크',
        copy: '복사',
        myCommunity: '내 커뮤니티',
        cur: '현재의',
        look: '부하 직원 보기',
        bindAddress: 'ID LABS 서약 여정을 시작하려면 초대한 사람의 주소를 바인딩하세요.',
        inputAddress: '초대하는 사람의 주소를 입력하세요.',
        bind: '묶다'
    },
    home:{
        title1: '탈중앙화, 비구속,',
        title2: '자금 확보를 위한 원스톱 서약 플랫폼',
        desc: 'ID LABS는 디파이 스테이킹에 인공지능(AI)을 도입한 탈중앙화 비수탁형 오픈소스 스테이킹 플랫폼으로, 금융 거래를 중심으로 대량의 퍼블릭 데이터가 생성되고 이 막대한 금융 데이터를 교육 및 개선에 사용 예상 자산 가격 변동에서 수익을 극대화하려고 시도하는 재정 거래 봇과 같은 인공 지능(AI) 모델.',
        lp: '약속',
        whitepaper: 'Whitepaper',
        earningTitle: '꾸준한 수입을 올리다',
        earning: {
            title1: '원칙에 충실하다',
            desc1: 'ID LABS의 설계는 강력한 원칙을 기반으로 합니다. 장기적으로 강력한 거래 플랫폼을 제공하고 모든 측면에서 커뮤니티 우선 순위와 공정한 대우를 제공하고 안정적인 부의 창출을 우선시합니다.',
            title2: '의식 혜택',
            desc2: 'ID LABS는 우리와 함께 플랫폼을 공동 제작하는 파트너에게 보상을 제공하며, 이 계약의 목적은 커뮤니티 팀과 가장 많이 기여한 파트너에게 보상하는 것입니다.',
            title3: '개발 개선',
            desc3: 'ID LABS는 플랫폼 사용자를 위한 우수한 개발 시작 계획을 제공하고 사용자가 조직 네트워크를 구축할 수 있도록 후한 보상 계획도 제공합니다.'
        },
        valueTitle: '우리의 핵심 가치',
        value: {
            title1: '통제되는 모든 것',
            desc1: '모든 사용자 자금은 스마트 계약에 잠겨 있으며 제3자는 이러한 자금에 직접 액세스할 수 없습니다. ID LABS는 비수탁적이며 어떠한 자산도 통제하지 않습니다. 제어하는 지갑에서 직접 사용할 수 있습니다.',
            title2: '간단한 조작',
            desc2: 'ID LABS의 페이지 디자인은 심플하고 사용하기 쉬우며 몇 개의 버튼만 클릭하면 사고 팔 수 있어 사용자가 보다 원활하게 사용할 수 있습니다.',
            title3: '공개 처리',
            desc3: 'ID LABS는 다양한 소셜 플랫폼을 통해 회사 발전의 각 단계를 회원들이 이해할 수 있도록 하고 지속적으로 최신 뉴스를 발표할 것입니다.',
            title4: '지역 사회',
            desc4: 'ID LABS는 대가족, 공동체 소유 기업과 같으므로 사용자가 개발, 마케팅, 정보 공유 등에 협력할 것을 적극 권장합니다.',
            title5: '장기 비전',
            desc5: 'ID LABS는 보다 장기적인 파트너십을 구축하기를 희망합니다. 우리는 장기적인 계획이 더 많은 투자로 이어질 것이라고 믿으며 단기적인 추세에 그쳐서는 안 됩니다.',
            title6: '신용 거래',
            desc6: '암호화폐에 관해서는 신뢰를 대신할 수 없습니다. 우리는 사용자의 자금과 정보의 안전을 보장하고 모든 사람에게 더 많은 이익을 가져다 줄 것입니다.',
        },
        friendTitle: '우리의 비즈니스 파트너'
    },
    lp: {
        title: '스테이킹 풀',
        desc: 'AI 자동 투자 모델은 귀하를 위해 가치를 창출합니다. 일부 토큰만 약속하면 수익을 올릴 수 있고 APR이 높으며 위험이 낮습니다.',
        back: '반환 서약',
        popupTitle: '서약 USDT',
        record: '서약 기록',
        usdtLp: 'USDT 스테이킹 - USDT 획득',
        redeem: '구원',
        money: '금액',
        startDate: '서약 시간',
        endDate: '만료 날짜',
        lp: '약속',
        day: '하늘',
        lpYet: '맹세하다',
        earn: '적립',
        extract: '인출가능 소득',
        get: '보상',
        cancel: '서약 취소',
        valueTilte: '왜 우리를 선택 했습니까?',
        type1Title: '글로벌 노출',
        type1Desc: '전 세계 100만 명 이상의 ID LABS 사용자에게 다가가세요.',
        type2Title: '유동성',
        type2Desc: 'TVL이 100,000,000 USDT를 초과하면 언제든지 서약을 취소하거나 수입을 인출할 수 있으며 계정에 실시간으로 적립됩니다.',
        type3Title: '높은 APR',
        type3Desc: '성숙한 AI 모델은 고수익 기회를 신속하게 발견하여 플랫폼이 고속으로 자금을 이체할 수 있도록 하며 APR은 98.55%-365.00%로 높습니다.',
        type4Title: '마케팅 지원',
        type4Desc: 'ID LABS 플랫폼을 공유하고 초특급 마케팅 보상을 받으세요',
        usable: '사용 가능',
        max: '최고',
        accredit: '인정 받은',
        quotaRemain: '남은 양'
    }
}

export default zh;
