const zh = {
    alert: {
        reLogin: '账号发生改变，请重新登录',
        copied: '复制成功',
        connectWallet: '请连接钱包',
        notBound: '未绑定邀请者，请进行绑定',
        inputParent: '请输入邀请者地址',
        addressError: '请输入正确的钱包地址',
        parentError: '邀请者地址未验证，请重新输入',
        boundSuccess: '账号绑定成功',
        boundFailed: '账号绑定失败，请重试',
        success: '成功',
        failed: '失败',
        soon: '即将开放...',
        insufficient: '余额不足',
        quotaInsufficient: '额度不足',
        amountError: '金额不正确',
        minAmountError: '最低',
        redeemed: '订单不存在或已赎回',
        enter: '确定',
        congratulation: '恭喜您合成'
    },
    tabbar: {
        home: 'ID LABS',
        lp: '质押',
        award: '奖励',
        user: '我的',
        whitepaper: 'Telegram',
        record: '记录',
        login: '登录',
        plan: '奖励计划',
        currentLp: '当前质押',
        dealRecord: '交易记录',
        lock: '安全',
        money: '资产',
        quit: '登出',
        invite: '请绑定邀请人地址，开启ID LABS质押之旅',
        binding: '立即绑定',
        btn: '解锁钱包'
    },
    award:{
        title: '奖励',
        desc: '分享ID LABS，助力平台营销，将获得高额奖励',
        await: '待领取奖励',
        get: '领取',
        all: '奖励总计',
        yetGet: '已领取',
        record: '奖励记录',
        allAward: '总奖励',
        type1: '直推奖励',
        type2: '级别奖励',
        type3: '分红奖励',
        type4: '平级奖励',
        more: '更多...'
    },
    user: {
        title: '我的个人中心',
        desc: '社区管理，通过您的邀请链接邀请更多朋友注册，组建自己的营销社区',
        date: '注册时间',
        type1: '直推成员',
        type2: '团队成员',
        type3: '个人质押',
        type4: '团队质押',
        my: '我的邀请链接',
        copy: '复制',
        myCommunity: '我的社区',
        cur: '当前',
        look: '查看下级',
        bindAddress: '请绑定邀请人地址，开启ID LABS质押之旅',
        inputAddress: '请输入邀请人地址',
        bind: '绑定'
    },
    home:{
        title1: '去中心化，非托管，',
        title2: '资金安全的一站式质押平台',
        desc: 'ID LABS是一个去中心、非托管和开源的质押平台，引入了人工智能 (AI)于DeFi质押，其主要好处是围绕金融交易生成大量公共数据和这一庞大的金融数据集合可用于训练和改进人工智能 (AI) 模型，例如试图从预期资产价格波动中获得最大收益的套利机器人。',
        lp: '质押',
        whitepaper: '白皮书',
        earningTitle: '赢取稳健收益',
        earning: {
            title1: '坚持原则',
            desc1: 'ID LABS的设计基于强大的原则：为长期性提供稳健的交易平台，社区优先和在各方面的公平处理，并优先考虑稳定的财富创造。',
            title2: '仪式效益',
            desc2: 'ID LABS奖励与我们一起共同创造平台的伙伴和该协定目的在于奖励社区团队和付出最多贡献的伙伴。',
            title3: '促进发展',
            desc3: 'ID LABS为平台用户提供了一个优良的开发启动计划，也提供了一个丰厚的奖励计划，帮助用户建立他们的组织网络。'
        },
        valueTitle: '我们的核心价值',
        value: {
            title1: '一切掌控',
            desc1: '所有用户资金都被锁定在智能合约中，没有任何第三方可以直接访问这些资金。ID LABS 是非托管的，从不控制任何资产。 它可以直接从您控制的钱包中使用。',
            title2: '简单操作',
            desc2: 'ID LABS 的页面设计简单以及容易上手，只需轻松点击几个按钮，就能做买卖，让用户们更加顺畅的使用。',
            title3: '公开处理',
            desc3: 'ID LABS 会通过各种社交平台，让会员们了解公司发展的每一层阶段并持续性公布最新消息。',
            title4: '社区',
            desc4: 'ID LABS 就像个大家庭，是一家社区所有的公司，因此我们非常鼓励用户们在开发，营销，资讯共享等方面进行合作。',
            title5: '目光长远',
            desc5: 'ID LABS 希望能建立更多的长期合作伙伴关系。我们相信长久的计划将带来更多的投资，不该止步于短暂的趋势。',
            title6: '信用',
            desc6: '在加密货币方面，信任是无可替代的。我们会确保用户的资金和资料的安全，并保证会持续为大家带来更多的利润。',
        },
        friendTitle: '我们的合作伙伴'
    },
    lp: {
        title: '质押池',
        desc: 'AI自动投资模型为您创造价值，只需要质押一些代币即可赚取，高APR，低风险',
        back: '返回质押',
        popupTitle: '质押USDT',
        record: '质押记录',
        usdtLp: '质押USDT - 赚取USDT',
        redeem: '赎回',
        money: '金额',
        startDate: '质押时间',
        endDate: '到期时间',
        lp: '质押',
        day: '天',
        lpYet: '已质押',
        earn: '已赚取收益',
        extract: '可提取收益',
        get: '收获',
        cancel: '取消质押',
        valueTilte: '为什么选择我们?',
        type1Title: '全球曝光',
        type1Desc: '触及全球超过100万的 ID LABS用户。',
        type2Title: '流动性',
        type2Desc: 'TVL超过100,000,000 USDT，随时取消质押或提取收益，实时到账',
        type3Title: '高APR',
        type3Desc: '成熟的AI模型，迅速发现高利机会，让平台资金高速流转，APR高达98.55%-365.00%',
        type4Title: '营销助力',
        type4Desc: '分享ID LABS平台，获得超高营销奖励',
        usable: '可用',
        max: '最大',
        accredit: '授权',
        quotaRemain: '剩余额度'
    }
}

export default zh;
