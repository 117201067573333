<template>
    <div id="app">
        <router-view @showTabBar="showTabBar" />
        <div v-if="show">
            <div id="nav">
                <router-link to="/">
                    <img src="./static/images/home.png" v-if="link" />
                    <img src="./static/images/home_s.png" v-else />
                    <span :style="!link?'color: #20EEA4':''">{{$t('tabbar.home')}}</span>
                </router-link>
                <router-link to="/lp">
                    <img src="./static/images/lp.png" v-if="link!=='lp' && link!=='lpv1' && link !== 'record'" />
                    <img src="./static/images/lp_s.png" v-else />
                    <span :style="link==='lp' || link==='lpv1' || link === 'record'?'color: #20EEA4':''">{{$t('tabbar.lp')}}</span>
                </router-link>
                <router-link to="/award">
                    <img src="./static/images/award.png" v-if="link!=='award'" />
                    <img src="./static/images/award_s.png" v-else />
                    <span :style="link==='award'?'color: #20EEA4':''">{{$t('tabbar.award')}}</span>
                </router-link>
                <router-link to="/user">
                    <img src="./static/images/user.png" v-if="link!=='user'" />
                    <img src="./static/images/user_s.png" v-else />
                    <span :style="link==='user'?'color: #20EEA4':''">{{$t('tabbar.user')}}</span>
                </router-link>
            </div>
            <!-- <div id="nav" v-else>
                <router-link to="/">
                    <img src="./static/images/home.png" v-if="link" />
                    <img src="./static/images/home_s.png" v-else />
                    <span :style="!link?'color: #20EEA4':''">{{$t('tabbar.home')}}</span>
                </router-link>
                <router-link to="/lp">
                    <img src="./static/images/lp.png" v-if="link!=='lp'" />
                    <img src="./static/images/lp_s.png" v-else />
                    <span :style="link==='lp'?'color: #20EEA4':''">{{$t('tabbar.lp')}}</span>
                </router-link>
                <router-link to="/team">
                    <img src="./static/images/team.png" v-if="link!=='team'" />
                    <img src="./static/images/team_s.png" v-else />
                    <span :style="link==='team'?'color: #20EEA4':''"> Team </span>
                </router-link>
                <router-link to="/bonus">
                    <img src="./static/images/bonus.png" v-if="link!=='bonus'" />
                    <img src="./static/images/bonus_s.png" v-else />
                    <span :style="link==='bonus'?'color: #20EEA4':''">Bonus</span>
                </router-link>
                <router-link to="/record">
                    <img src="./static/images/record.png" v-if="link!=='record'" />
                    <img src="./static/images/record_s.png" v-else />
                    <span :style="link==='record'?'color: #20EEA4':''">{{$t('tabbar.record')}}</span>
                </router-link>
            </div> -->
        </div>
    </div>
</template>
<script>
import { throwStatement } from '@babel/types';
    export default ({
        data() {
            return{
                link: '',
                show: true,
            }
        },
        computed:{
            award(){
                if(this.link === 'invite'||this.link === 'award' || this.link == 'team' ||this.link === 'bonus' || this.link === 'record'
                    ||this.link ==='earnings'||this.link === 'plan' || this.link === 'currentStakeList' || this.link == 'transactionHistory'){
                    return true
                }else{
                    return false
                }
            }
        },
        watch:{
            $route(newval,oldval){
                this.link = newval.path.slice(1,newval.path.length);
            },
        },
        methods:{
            showTabBar(bool){
                this.show = bool;
            },
            handleNewEvent(event) {
            },
        }
    })
</script>

<style>
    .root{
        min-height: calc(100vh - 20px);
    }
	.el-message{
		width: 100% !important
	}
    .max-width-all{
        max-width: 1200px;
    }
    .no-data{
        width: 100%;
        margin: 150px 0;
        display: flex;
        justify-content: center;
    }
    .no-data img{
        width: 150px;
    }
    body {
        margin: 0;
        font-size: 14px;
        color: #212121;
        /* background-color: #5e5f60; */
    }
    #app {
        width: 100%;
        min-height: 100vh;
        overflow: hidden;
        margin: 0 auto;
        background: #F5F4F6;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
    }
    #nav {
        position: fixed;
        bottom: 0;
        z-index: 1001;
        width: 100%;
        height: 70px;
        display: none;
        justify-content: space-around;
        background-color: rgb(33, 35, 49);
        /* border-top: 1px solid #e0e0e0; */
    }
    #nav a{
        color: #7489B9;
        text-decoration: none;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        align-items: center;
    }
    #nav a img{
        width: 24px;
        margin: 10px 0 8px;
    }
    button:focus{
        outline: none;
    }
    .module-wid{
        width: calc(100% - 52px);
        margin: 0 auto;
        background-color: white;
        padding: 16px;
        border-radius: 4px;
    }
    button{
        border: none;
        cursor: pointer;
    }
    .more{
        text-align: center;
        margin: 20px auto;
        font-size: 14px;
        color:#BECFD2;
        display: block;
        cursor: pointer;
    }
    .copyright{
        font-size: 12px;
        text-align: center;
        margin-top: 60px;
    }
    @media screen and (max-width: 900px) {
        #nav{
            display: flex;
        }
        .copyright{
            display: none;
        }
        .no-data{
            margin: 70px 0;
        }
        .no-data img{
            width: 100px;
        }
    }
</style>
