const zh = {
    alert: {
        reLogin: 'The account has been changed, please log in again',
        copied: 'Copied',
        connectWallet: 'Please connect wallet',
        notBound: 'Unbound inviter, please bind',
        inputParent: 'Please enter inviter\'s address',
        addressError: 'Please enter the correct wallet address',
        parentError: 'Inviter\'s address is not verified, please re-enter',
        boundSuccess: 'The account is bound successfully',
        boundFailed: 'Account binding failed, please try again',
        success: 'Success',
        failed: 'Fail',
        soon: 'Opening soon...',
        insufficient: 'Insufficient balance',
        quotaInsufficient: 'Insufficient quota',
        amountError: 'Incorrect amount',
        minAmountError: 'Minimum',
        redeemed: 'Order does not exist or has been redeemed',
        enter: 'Sure',
        congratulation: 'Congratulations on your synthesis'
    },
    tabbar: {
        home: 'ID LABS',
        lp: 'Stake',
        award: 'Award',
        user: 'User',
        whitepaper: 'whitepaper',
        record: 'Record',
        login: 'Log in',
        plan: 'Reward program',
        currentLp: 'Current stake',
        dealRecord: 'Record',
        lock: 'Safety',
        money: 'Assets',
        quit: 'Sign out',
        invite: 'Please bind the address of the inviter to start the ID LABS stake journey',
        binding: 'Bind now',
        btn: 'Unlock wallet'
    },
    award:{
        title: 'Award',
        desc: 'Share ID LABS, help platform marketing, and get high rewards',
        await: 'Unclaimed',
        get: 'Claim',
        all: 'Total',
        yetGet: 'Claimed',
        record: 'Reward record',
        allAward: 'Total reward',
        type1: 'Direct reward',
        type2: 'Level rewards',
        type3: 'Dividend bonus',
        type4: 'Same Level',
        more: 'More...'
    },
    user: {
        title: 'My personal center',
        desc: 'Community management, invite more friends to register through your invitation link, and form your own marketing community',
        date: 'Reg time',
        type1: 'Direct',
        type2: 'Team',
        type3: 'Stake',
        type4: 'Team Stake',
        my: 'My invite link',
        copy: 'Copy',
        myCommunity: 'My community',
        cur: 'Current',
        look: 'View subordinates',
        bindAddress: 'Please bind the address of the inviter to start the ID LABS stake journey',
        inputAddress: 'Please enter the inviter\'s address',
        bind: 'To bind'
    },
    home:{
        title1: 'decentralized, non-custodial,',
        title2: 'One-stop stake platform for fund security',
        desc: 'ID LABS is a decentralized, non-custodial and open-source staking platform that introduces artificial intelligence (AI) to DeFi staking. Its main benefit is that a large amount of public data is generated around financial transactions and this huge collection of financial data can be used for training and improvement Artificial intelligence (AI) models, such as arbitrage bots that attempt to maximize returns from expected asset price fluctuations.',
        lp: 'Stake',
        whitepaper: 'Whitepaper',
        earningTitle: 'Earn steady income',
        earning: {
            title1: 'Stick to the principle',
            desc1: 'The design of ID LABS is based on strong principles: providing a robust trading platform for the long-term, community priority and fair treatment in all aspects, and prioritizing stable wealth creation.',
            title2: 'Ritual benefit',
            desc2: 'ID LABS rewards partners who co-create the platform with us and the purpose of this agreement is to reward community teams and partners who contribute the most.',
            title3: 'Improve development',
            desc3: 'ID LABS provides an excellent development start-up plan for platform users, and also provides a generous reward plan to help users build their organizational network.'
        },
        valueTitle: 'Our core values',
        value: {
            title1: 'Everything under control',
            desc1: 'All user funds are locked in smart contracts and no third party has direct access to these funds. ID LABS is non-custodial and never controls any assets. It can be used directly from the wallet you control.',
            title2: 'Simple operation',
            desc2: 'The page design of ID LABS is simple and easy to use. You only need to click a few buttons to buy and sell, allowing users to use it more smoothly.',
            title3: 'Public processing',
            desc3: 'ID LABS will let members understand each stage of the company\'s development through various social platforms and continuously announce the latest news.',
            title4: 'Community',
            desc4: 'ID LABS is like a big family, a community-owned company, so we strongly encourage users to cooperate in development, marketing, information sharing, etc.',
            title5: 'Long-term vision',
            desc5: 'ID LABS hopes to establish more long-term partnerships. We believe that long-term planning will lead to more investment, and should not stop at short-term trends.',
            title6: 'Credit',
            desc6: 'There is no substitute for trust when it comes to cryptocurrencies. We will ensure the safety of users\' funds and information, and ensure that we will continue to bring more profits to everyone.',
        },
        friendTitle: 'Our Partners'
    },
    lp: {
        title: 'Staking pool',
        desc: 'AI automatic investment model creates value for you, you only need to stake some tokens to earn, high APR, low risk',
        back: 'Return Stake',
        popupTitle: 'Stake USDT',
        record: 'Stake record',
        usdtLp: 'Stake USDT - Earn USDT',
        redeem: 'redemption',
        money: 'Amount',
        startDate: 'Stake time',
        endDate: 'Expire date',
        lp: 'Stake',
        day: 'Days',
        lpYet: 'Staked',
        earn: 'Earned',
        extract: 'Withdrawable income',
        get: 'Claim',
        cancel: 'Redeem',
        valueTilte: 'Why choose us?',
        type1Title: 'Global exposure',
        type1Desc: 'Reach over 1 million ID LABS users worldwide.',
        type2Title: 'Fluidity',
        type2Desc: 'TVL more than 100,000,000 USDT;As & when can withdraw;timely withdrawal to your wallet.',
        type3Title: 'High APR',
        type3Desc: 'The mature AI model quickly discovers high-profit opportunities, allowing the platform to transfer funds at a high speed, and the APR is as high as 98.55%-365.00%',
        type4Title: 'Marketing support',
        type4Desc: 'Share the ID LABS platform and get super high marketing rewards',
        usable: 'Available',
        max: 'Max',
        accredit: 'Approve',
        quotaRemain: 'Quota'
    }
}

export default zh;
