const zh = {
    alert: {
        reLogin: 'บัญชีมีการเปลี่ยนแปลง กรุณาเข้าสู่ระบบอีกครั้ง',
        copied: 'คัดลอกสำเร็จ',
        connectWallet: 'กรุณาเชื่อมต่อกระเป๋าเงิน',
        notBound: 'ผู้เชิญที่ไม่ถูกผูกมัด โปรดผูกมัด',
        inputParent: 'กรุณากรอกที่อยู่ของผู้เชิญ',
        addressError: 'กรุณากรอกที่อยู่กระเป๋าเงินให้ถูกต้อง',
        parentError: 'ที่อยู่ของผู้เชิญไม่ได้รับการยืนยัน โปรดป้อนใหม่',
        boundSuccess: 'ผูกบัญชีเรียบร้อยแล้ว',
        boundFailed: 'การผูกบัญชีล้มเหลว โปรดลองอีกครั้ง',
        success: 'ความสำเร็จ',
        failed: 'ล้มเหลว',
        soon: 'เปิดเร็ว ๆ นี้...',
        insufficient: 'ยอดเงินคงเหลือไม่เพียงพอ',
        quotaInsufficient: 'โควต้าไม่เพียงพอ',
        amountError: 'จำนวนเงินไม่ถูกต้อง',
        minAmountError: 'ต่ำสุด',
        redeemed: 'ไม่มีคำสั่งซื้อหรือมีการแลกใช้',
        enter: 'แน่นอน',
        congratulation: 'ขอแสดงความยินดีกับการสังเคราะห์ของคุณ'
    },
    tabbar: {
        home: 'ไอดีแล็บ',
        lp: 'จำนำ',
        award: 'รางวัล',
        user: 'ของฉัน',
        whitepaper: 'โทรเลข',
        record: 'บันทึก',
        login: 'เข้าสู่ระบบ',
        plan: 'โปรแกรมรางวัล',
        currentLp: 'คำมั่นสัญญาในปัจจุบัน',
        dealRecord: 'บันทึกการทำธุรกรรม',
        lock: 'ความปลอดภัย',
        money: 'สินทรัพย์',
        quit: 'ออกจากระบบ',
        invite: 'โปรดผูกที่อยู่ของผู้เชิญเพื่อเริ่มการเดินทางจำนำ ID LABS',
        binding: 'ผูกตอนนี้',
        btn: 'ปลดล็อคกระเป๋าเงิน'
    },
    award:{
        title: 'รางวัล',
        desc: 'แบ่งปัน ID LABS ช่วยการตลาดแพลตฟอร์ม และรับรางวัลสูง',
        await: 'รางวัลที่รอดำเนินการ',
        get: 'รับ',
        all: 'รางวัลรวม',
        yetGet: 'ได้รับ',
        record: 'บันทึกรางวัล',
        allAward: 'รางวัลรวม',
        type1: 'รางวัลผู้อ้างอิงโดยตรง',
        type2: 'รางวัลระดับ',
        type3: 'โบนัสเงินปันผล',
        type4: 'ระดับแบน',
        more: 'มากกว่า...'
    },
    user: {
        title: 'ศูนย์ส่วนบุคคลของฉัน',
        desc: 'การจัดการชุมชน เชิญเพื่อนมาลงทะเบียนผ่านลิงก์คำเชิญของคุณ และสร้างชุมชนการตลาดของคุณเอง',
        date: 'เวลาลงทะเบียน',
        type1: 'สมาชิกผลักดันโดยตรง',
        type2: 'สมาชิกในทีม',
        type3: 'คำมั่นสัญญาส่วนบุคคล',
        type4: 'คำมั่นสัญญาของทีม',
        my: 'ลิงค์คำเชิญของฉัน',
        copy: 'สำเนา',
        myCommunity: 'ชุมชนของฉัน',
        cur: 'ปัจจุบัน',
        look: 'ดูผู้ใต้บังคับบัญชา',
        bindAddress: 'โปรดผูกที่อยู่ของผู้เชิญเพื่อเริ่มการเดินทางจำนำ ID LABS',
        inputAddress: 'กรุณากรอกที่อยู่ของผู้เชิญ',
        bind: 'ที่จะผูก'
    },
    home:{
        title1: 'กระจายอำนาจไม่ใช่อารักขา',
        title2: 'แพลตฟอร์มจำนำแบบครบวงจรสำหรับความปลอดภัยของกองทุน',
        desc: 'ID LABS เป็นแพลตฟอร์มการเดิมพันแบบโอเพ่นซอร์สแบบกระจายศูนย์ที่ไม่ต้องดูแลและควบคุมซึ่งแนะนำปัญญาประดิษฐ์ (AI) ให้กับการเดิมพันแบบ DeFi ประโยชน์หลักของมันคือข้อมูลสาธารณะจำนวนมากถูกสร้างขึ้นเกี่ยวกับธุรกรรมทางการเงินและการรวบรวมข้อมูลทางการเงินจำนวนมหาศาลนี้สามารถ ใช้สำหรับการฝึกอบรมและปรับปรุงโมเดลปัญญาประดิษฐ์ (AI) เช่น บอทเก็งกำไรที่พยายามเพิ่มผลตอบแทนสูงสุดจากความผันผวนของราคาสินทรัพย์ที่คาดไว้',
        lp: 'จำนำ',
        whitepaper: 'กระดาษสีขาว',
        earningTitle: 'รับรายได้ที่มั่นคง',
        earning: {
            title1: 'ยึดมั่นในหลักการ',
            desc1: 'การออกแบบ ID LABS ยึดตามหลักการที่แข็งแกร่ง: จัดหาแพลตฟอร์มการซื้อขายที่แข็งแกร่งสำหรับระยะยาว ให้ความสำคัญกับชุมชนและการปฏิบัติอย่างยุติธรรมในทุกด้าน และให้ความสำคัญกับการสร้างความมั่งคั่งที่มั่นคง',
            title2: 'ประโยชน์ทางพิธีกรรม',
            desc2: 'ID LABS ให้รางวัลแก่พันธมิตรที่ร่วมสร้างแพลตฟอร์มกับเรา และจุดประสงค์ของข้อตกลงนี้คือเพื่อให้รางวัลแก่ทีมชุมชนและพันธมิตรที่มีส่วนร่วมมากที่สุด',
            title3: 'ปรับปรุงการพัฒนา',
            desc3: 'ID LABS มอบแผนการเริ่มต้นการพัฒนาที่ยอดเยี่ยมแก่ผู้ใช้แพลตฟอร์ม รวมถึงโปรแกรมรางวัลมากมายที่จะช่วยให้ผู้ใช้สร้างเครือข่ายองค์กรของตน'
        },
        valueTitle: 'ค่านิยมหลักของเรา',
        value: {
            title1: 'ทุกอย่างอยู่ภายใต้การควบคุม',
            desc1: 'เงินของผู้ใช้ทั้งหมดถูกล็อคไว้ในสัญญาอัจฉริยะ และไม่มีบุคคลที่สามเข้าถึงเงินเหล่านี้ได้โดยตรง ID LABS ไม่ใช่การดูแลและไม่เคยควบคุมทรัพย์สินใดๆ สามารถใช้ได้โดยตรงจากกระเป๋าเงินที่คุณควบคุม',
            title2: 'ใช้งานง่าย',
            desc2: 'การออกแบบหน้าของ ID LABS นั้นเรียบง่ายและใช้งานง่าย คุณเพียงแค่คลิกปุ่มไม่กี่ปุ่มเพื่อซื้อและขาย ทำให้ผู้ใช้สามารถใช้งานได้อย่างราบรื่นยิ่งขึ้น',
            title3: 'การประมวลผลสาธารณะ',
            desc3: 'ID LABS จะช่วยให้สมาชิกเข้าใจแต่ละขั้นตอนของการพัฒนาของบริษัทผ่านแพลตฟอร์มโซเชียลต่างๆ และประกาศข่าวสารล่าสุดอย่างต่อเนื่อง',
            title4: 'ชุมชน',
            desc4: 'ID LABS เป็นเหมือนครอบครัวใหญ่ เป็นบริษัทที่ชุมชนเป็นเจ้าของ ดังนั้นเราขอแนะนำให้ผู้ใช้ร่วมมือในการพัฒนา ทำการตลาด แบ่งปันข้อมูล ฯลฯ',
            title5: 'วิสัยทัศน์ระยะยาว',
            desc5: 'ID LABS หวังที่จะสร้างความร่วมมือระยะยาวมากขึ้น เราเชื่อว่าการวางแผนระยะยาวจะนำไปสู่การลงทุนที่มากขึ้น และไม่ควรหยุดที่แนวโน้มระยะสั้น',
            title6: 'เครดิต',
            desc6: 'ไม่มีอะไรมาแทนที่ความไว้วางใจเมื่อพูดถึง cryptocurrencies เราจะรับรองความปลอดภัยของเงินทุนและข้อมูลของผู้ใช้ และรับรองว่าเราจะนำผลกำไรมาสู่ทุกคนอย่างต่อเนื่อง',
        },
        friendTitle: 'พันธมิตรทางธุรกิจของเรา'
    },
    lp: {
        title: 'สระว่ายน้ำปักหลัก',
        desc: 'รูปแบบการลงทุนอัตโนมัติของ AI สร้างมูลค่าให้กับคุณ คุณเพียงแค่จำนำโทเค็นบางส่วนเพื่อรับรายได้ APR สูง ความเสี่ยงต่ำ',
        back: 'คืนคำมั่นสัญญา',
        popupTitle: 'จำนำ USDT',
        record: 'บันทึกจำนำ',
        usdtLp: 'เดิมพัน USDT - รับ USDT',
        redeem: 'การไถ่ถอน',
        money: 'จำนวนเงิน',
        startDate: 'เวลาจำนำ',
        endDate: 'วันที่หมดอายุ',
        lp: 'จำนำ',
        day: 'ท้องฟ้า',
        lpYet: 'จำนำ',
        earn: 'ได้รับ',
        extract: 'รายได้ที่ถอนได้',
        get: 'รางวัล',
        cancel: 'ยกเลิกการจำนำ',
        valueTilte: 'ทำไมถึงเลือกพวกเรา?',
        type1Title: 'การเปิดรับทั่วโลก',
        type1Desc: 'เข้าถึงผู้ใช้ ID LABS มากกว่า 1 ล้านคนทั่วโลก',
        type2Title: 'ความลื่นไหล',
        type2Desc: 'TVL เกิน 100,000,000 USDT คุณสามารถยกเลิกการจำนำหรือถอนรายได้ได้ตลอดเวลา และบัญชีจะได้รับเครดิตตามเวลาจริง',
        type3Title: 'เม.ย. สูง',
        type3Desc: 'โมเดล AI ที่ครบกำหนดจะค้นพบโอกาสทำกำไรสูงอย่างรวดเร็ว ทำให้แพลตฟอร์มสามารถโอนเงินด้วยความเร็วสูง และ APR สูงถึง 98.55%-365.00%',
        type4Title: 'การสนับสนุนด้านการตลาด',
        type4Desc: 'แบ่งปันแพลตฟอร์ม ID LABS และรับรางวัลทางการตลาดระดับสูง',
        usable: 'มีอยู่',
        max: 'ขีดสุด',
        accredit: 'ได้รับอนุญาต',
        quotaRemain: 'จำนวนคงเหลือ'
    }
}

export default zh;
