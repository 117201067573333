import { createI18n } from 'vue-i18n/index';


import cn from './config/cn';
import en from './config/en';
import tc from './config/cn-tw';
import jp from './config/jp';
import kr from './config/kr';
import id from './config/id';
import vi from './config/vi';
import th from './config/th';

const i18n = createI18n({
    locale: localStorage.getItem('locale') || 'en',
    silentTranslationWarn: true,
    messages: {
        cn,
        en,
        tc,
        jp,
        kr,
        id,
        vi,
        th
    }
})

export default i18n;
